import React from "react"
import PropTypes from "prop-types"
import Idea from './Idea';
import getToken from './getToken';
import _ from 'lodash';


class IdeasList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ideas: null,
      sorted: null
    };
    this._handleVote = this._handleVote.bind(this);
    this._handleHide = this._handleHide.bind(this);
    this._handleReport = this._handleReport.bind(this);
  }


  componentDidMount(){
    this._fetchIdeas();
  }


  _fetchIdeas(){
    fetch('/api')
      .then(res => res.json())
      .then(res => {
        this.setState({ ideas: res.data });
      })
  }


  _handleVote(value, id){
    const idea = this._getIdea(id);
    idea.score += value;
    if(value === 1) idea.up_votes += 1;
    if(value === -1) idea.down_votes += 1;
    this._update(idea);
  }


  _handleHide(id){
    const idea = this._getIdea(id);
    idea.hidden = true;
    this._update(idea);
  }


  _getIdea(id){
    return _.find(this.state.ideas, ['id', id]);
  }


  _update(idea){
    fetch(`/ideas/${idea.id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': getToken(),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idea: idea
      })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) {
          this.setState({ errors: res.errors });
          console.log(res.errors);
        }
        else this._fetchIdeas();
      })
  }


  _handleReport(id){
    const idea = this._getIdea(id);
    if(idea) this._createReport(id);
  }


  _createReport(ideaId){
    fetch('/reports', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getToken(),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        report: {
          idea_id: ideaId
        }
      })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) this.setState({ errors: res.errors });
        // this should hide the reported idea
        else this._fetchIdeas();
      })
  }


  render () {

    if(!this.state.ideas) return <h3>Loading Ideas ...</h3>

    // const shuffled = _.shuffle(this.state.ideas);

    const sorted = _.sortBy(this.state.ideas, (i) => i.score).reverse();

    const ideas = sorted.map((idea, i) => {
      return (
        <li key={'ideasList'+i}>
          <Idea
            idea={idea}
            key={i + '-' + idea.idea}
            handleVote={this._handleVote}
            handleHide={this._handleHide}
            handleReport={this._handleReport}
            loggedIn={this.props.loggedIn}
          />
        </li>
      )
    })

    return (
      <div id='ideas'>
        <ul>
          {ideas}
        </ul>
      </div>
    );
  }
}


IdeasList.propTypes = {
  loggedIn: PropTypes.bool
};


export default IdeasList
