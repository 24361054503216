import React from "react"
import PropTypes from "prop-types"
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'

const customStyle={
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '4rem',
    backgroundColor       : 'black'
  }
}

class ConfirmReport extends React.Component {

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={customStyle}
      >
        <p
          className='bold lead white'
        >
          {this.props.message}
        </p>

        <div
          className='flex-container align-center'
        >
          <div
            className='idea-button-container mm'
            onClick={this.props.handleConfirm}
          >
            <FontAwesomeIcon
              icon={faCheckCircle}
              className='report-button report-action-button'
            />
          </div>

          <div
            className='idea-button-container mm'
            onClick={this.props.toggle}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              className='report-button report-action-button'
            />
          </div>

        </div>


      </Modal>
    );
  }
}

ConfirmReport.propTypes = {
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  handleConfirm: PropTypes.func
};

ConfirmReport.defaultProps = {
  isOpen: false
}
export default ConfirmReport
