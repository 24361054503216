import React from "react"
import PropTypes from "prop-types"




class ReportedIdea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null
    }
    this._toggleHide = this._toggleHide.bind(this);
  }


  _getToken(){
    return document.querySelector('[name="csrf-token"]').content;
  }


  _toggleHide(){
    const { idea } = this.props;
    idea.hidden = !idea.hidden;

    fetch(`/ideas/${idea.id}`, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': this._getToken(),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        idea: idea
      })
    })
      .then(res => res.json())
      .then(res => {
        if(!res.success) {
          this.setState({ errors: res.errors });
          console.log(res.errors);
        }
        else this.setState({ errors: null });
      })
  }


  render () {

    const { idea, score, id, hidden } = this.props.idea

    if(this.state.errors) console.log(this.state.errors);

    return (
      <div
        id={'idea' + id}
        className='idea mp flex-container align-justify'
      >
        <h1>
          {idea}
        </h1>



        <div
          className='idea-buttons flex-container align-middle'
        >
          <h1
            className='mmr nm'
          >
            {score} Votes
          </h1>

          <button
            className='button nm mmr'
            onClick={this._toggleHide}
          >
            {hidden ? 'Show' : 'Hide'}
          </button>
        </div>

      </div>
    );
  }
}

ReportedIdea.propTypes = {
  idea: PropTypes.object
};
export default ReportedIdea
