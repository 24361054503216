import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUp,
  faArrowDown,
  faComment,
  faExclamation
} from '@fortawesome/free-solid-svg-icons'
import ConfirmReport from './ConfirmReport';



class Idea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      upClicked: false,
      downClicked: false,
      modalOpen: false
    };
  }

  render () {

    const { idea, score, id } = this.props.idea

    return (
      <div
        id={'idea' + id}
        className='idea mpl mpr spt spb flex-container align-justify'
      >
        <h4 className='italic'>
          {`"${idea}"`}
        </h4>



        <div
          className='idea-buttons flex-container align-middle'
        >


          {this.props.loggedIn &&
            <button
              className='button nm smr'
              onClick={()=>this.props.handleHide(id)}
            >
              Hide
            </button>
          }

          <span
            className={`idea-button-container ${this.state.upClicked ? 'disable-hover' : ''}`}
            onClick={()=>{
              if(!this.state.upClicked) {
                this.setState({ upClicked: true }, ()=>this.props.handleVote(1, id));
              }
            }}
          >
            <FontAwesomeIcon
              icon={faArrowUp}
              className={`idea-button`}
            />
          </span>

          <h3
            className='nm'
          >
            {score}
          </h3>

          <span
            className='idea-button-container'
            onClick={()=>{
              if(!this.state.downClicked) {
                this.setState({ downClicked: true }, ()=>this.props.handleVote(-1, id));
              }
            }}
          >
            <FontAwesomeIcon
              icon={faArrowDown}
              className={`idea-button ${this.state.downClicked ? 'disable-hover' : ''}`}

            />
          </span>

          <span
            data-toggle='share-idea'
            className='idea-button-container'
          >
            <FontAwesomeIcon
              icon={faComment}
              className='idea-button'
              id={'share-idea-' + id}
            />
          </span>
          <span
            className='idea-button-container'
            onClick={()=>this.setState({ modalOpen: true })}
          >
            <FontAwesomeIcon
              icon={faExclamation}
              className='report-button'
            />
          </span>
        </div>

        <ConfirmReport
          message='Do you want to report this as offensive?'
          isOpen={this.state.modalOpen}
          toggle={()=>this.setState({modalOpen: !this.state.modalOpen})}
          handleConfirm={()=>{
            this.props.handleReport(id);
            this.setState({modalOpen:false});
          }}
        />

      </div>
    );
  }
}

Idea.propTypes = {
  idea: PropTypes.object,
  loggedIn: PropTypes.bool,
};
export default Idea
